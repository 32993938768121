import React, { useEffect, useState, useMemo } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./ManageOfflineData.module.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import PreLoader from "../../components/common/PreLoader";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/material";
import { setAppTitle } from "../../store/slices/settingsSlice";
import ProgressBar from "../../components/common/ProgressBar";
import { useSelector } from "react-redux";
import {
  saveEnrolmentGuardianData,
  saveEnrolmentParticipantData,
  saveParticipantUserData,
  saveEnrolmentStatus,
} from "../../services/enrolment.service";
import { apiCreateEnrolment } from "../../services/api/enrolmentApi.service";
import { apiCreateParticipant } from "../../services/api/participantApi.service";
import { setOfflineEnrolments } from "../../store/slices/offlineDataSlice";
import {
  saveFGDA1ParticipantData,
  saveFGDA1ParticipantUserData,
  saveFGDA1Status,
  saveFGDA1GuardianData,
} from "../../services/fgda1.service";
import { apiCreateFGDA1 } from "../../services/api/subStudyFGDA1Api.service";
import { apiCreateFGDA3 } from "../../services/api/subStudyFGDA3Api.service";
import { apiCreateIDIA1 } from "../../services/api/subStudyIDIA1Api.service";
import { apiCreateIDIA3 } from "../../services/api/subStudyIDIA3Api.service";
import { apiCreateKIIA1 } from "../../services/api/subStudyKIIA1Api.service";
import { apiCreateKIIA3 } from "../../services/api/subStudyKIIA3Api.service";
import {
  saveFGDA3GuardianData,
  saveFGDA3ParticipantData,
  saveFGDA3ParticipantUserData,
  saveFGDA3Status,
} from "../../services/fgda3.service";
import {
  saveIDIA1GuardianData,
  saveIDIA1ParticipantData,
  saveIDIA1ParticipantUserData,
  saveIDIA1Status,
} from "../../services/idia1.service";
import {
  saveIDIA3GuardianData,
  saveIDIA3ParticipantData,
  saveIDIA3ParticipantUserData,
  saveIDIA3Status,
} from "../../services/idia3.service";
import {
  saveKIIA1GuardianData,
  saveKIIA1ParticipantData,
  saveKIIA1ParticipantUserData,
  saveKIIA1Status,
} from "../../services/kiia1.service";
import {
  saveKIIA3GuardianData,
  saveKIIA3ParticipantData,
  saveKIIA3ParticipantUserData,
  saveKIIA3Status,
} from "../../services/kiia3.service";

const ManageOfflineData = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [Loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(1);
  const { isDeviceOnline, loggedInUser } = useSelector(
    (state) => state.settings
  );
  const { offlineEnrolments } = useSelector((state) => state.offlineData);
  const [rows, setRows] = useState([]);
  const columns = [
    { field: "id", headerName: "ID", width: 50, hide: true },
    {
      field: "enrolment_date",
      headerName: "Enrolment Date",
      sortable: false,
      width: 130,
    },
    { field: "age_cat", headerName: "Age Cat", width: 150 },
    { field: "guardian", headerName: "Parent / Guardian", width: 160 },
    { field: "participant", headerName: "Participant", width: 160 },
    { field: "record_status", headerName: "Status", width: 200 },
  ];

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  const showSnackBar = (data) => {
    enqueueSnackbar(data.message, {
      variant: data.variant,
    });
  };

  const _showSuccessMessage = (success) => {
    showSnackBar({
      message: success
        ? "Enrolments synchronised successfully."
        : "Some enrolments failed to synchronise. Please try again.",
      variant: "success",
    });
  };

  const createNewEnrolment = async (participantId) => {
    const results = await apiCreateEnrolment(participantId);
    if (results.success) {
      const enrolementId = results.body;
      return enrolementId;
    } else {
      return null;
    }
  };

  const createNewParticipant = async (siteId) => {
    const results = await apiCreateParticipant(siteId);

    if (results.success) {
      const participantId = results.body;
      return participantId;
    } else {
      return null;
    }
  };

  const saveEnrolment = async (
    enrolmentState,
    settingsState,
    guardianResults,
    participantResults
  ) => {
    let _enrolmentState = JSON.parse(JSON.stringify(enrolmentState));
    setSyncInProgress(true);

    const _pageType =
      settingsState.currentFormIndex === 0
        ? settingsState.enrolmentForms[settingsState.currentFormIndex].type
        : settingsState.enrolmentForms[settingsState.currentFormIndex - 1].type;

    setLoading(true);

    const _participantId = await createNewParticipant(
      settingsState.loggedInUser.siteId
    );
    const _enrolmentId = await createNewEnrolment(_participantId);

    _enrolmentState.participant_id = _participantId;
    _enrolmentState.enrolment_id = _enrolmentId;

    const participantUpdateResults = await saveParticipantUserData(
      _enrolmentState,
      settingsState,
      isDeviceOnline
    );

    const saveEnrolmentStatusSuccess = await saveEnrolmentStatus(
      _enrolmentState.participant_id,
      _enrolmentState.enrolment_id,
      settingsState.recordStatus
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveEnrolmentGuardianData(
        _enrolmentState,
        settingsState,
        guardianResults,
        isDeviceOnline
      );
      const updateParticipantSuccess = await saveEnrolmentParticipantData(
        _enrolmentState,
        settingsState,
        participantResults,
        isDeviceOnline
      );
      setLoading(false);
      const result =
        updateGuardianSuccess &&
        updateParticipantSuccess &&
        participantUpdateResults &&
        saveEnrolmentStatusSuccess;

      return result;
    } else if (
      _pageType === "EMANCIPATED_MINOR" ||
      _pageType === "ADULT_18_PLUS"
    ) {
      const updateParticipantSuccess = await saveEnrolmentParticipantData(
        _enrolmentState,
        settingsState,
        participantResults,
        isDeviceOnline
      );
      setLoading(false);

      const result =
        updateParticipantSuccess &&
        participantUpdateResults &&
        saveEnrolmentStatusSuccess;
      _showSuccessMessage(result);

      return result;
    }
  };

  const createSubStudy = async (_participantId, studyType) => {
    if (studyType === "FGDA1") {
      const results = await apiCreateFGDA1(_participantId);
      if (results.success) {
        return results.body;
      }
    } else if (studyType === "FGDA3") {
      const results = await apiCreateFGDA3(_participantId);
      if (results.success) {
        return results.body;
      }
    } else if (studyType === "IDIA1") {
      const results = await apiCreateIDIA1(_participantId);
      if (results.success) {
        return results.body;
      }
    } else if (studyType === "IDIA3") {
      const results = await apiCreateIDIA3(_participantId);
      if (results.success) {
        return results.body;
      }
    } else if (studyType === "KIIA1") {
      const results = await apiCreateKIIA1(_participantId);
      if (results.success) {
        return results.body;
      }
    } else if (studyType === "KIIA3") {
      const results = await apiCreateKIIA3(_participantId);
      if (results.success) {
        return results.body;
      }
    }
  };

  const saveSubStudyParticipantUserData = async (
    _enrolmentState,
    settingsState,
    isDeviceOnline,
    studyType
  ) => {
    if (studyType === "FGDA1") {
      const updateResults = await saveFGDA1ParticipantUserData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateResults;
    } else if (studyType === "FGDA3") {
      const updateResults = await saveFGDA3ParticipantUserData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateResults;
    } else if (studyType === "IDIA1") {
      const updateResults = await saveIDIA1ParticipantUserData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateResults;
    } else if (studyType === "IDIA3") {
      const updateResults = await saveIDIA3ParticipantUserData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateResults;
    } else if (studyType === "KIIA1") {
      const updateResults = await saveKIIA1ParticipantUserData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateResults;
    } else if (studyType === "KIIA3") {
      const updateResults = await saveKIIA3ParticipantUserData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateResults;
    }
  };

  const saveSubStudyStatus = async (
    participant_id,
    enrolment_id,
    recordStatus,
    studyType
  ) => {
    if (studyType === "FGDA1") {
      const saveStatusSuccess = await saveFGDA1Status(
        participant_id,
        enrolment_id,
        recordStatus
      );
      return saveStatusSuccess;
    } else if (studyType === "FGDA3") {
      const saveStatusSuccess = await saveFGDA3Status(
        participant_id,
        enrolment_id,
        recordStatus
      );
      return saveStatusSuccess;
    } else if (studyType === "IDIA1") {
      const saveStatusSuccess = await saveIDIA1Status(
        participant_id,
        enrolment_id,
        recordStatus
      );
      return saveStatusSuccess;
    } else if (studyType === "IDIA3") {
      const saveStatusSuccess = await saveIDIA3Status(
        participant_id,
        enrolment_id,
        recordStatus
      );
      return saveStatusSuccess;
    } else if (studyType === "KIIA1") {
      const saveStatusSuccess = await saveKIIA1Status(
        participant_id,
        enrolment_id,
        recordStatus
      );
      return saveStatusSuccess;
    } else if (studyType === "KIIA3") {
      const saveStatusSuccess = await saveKIIA3Status(
        participant_id,
        enrolment_id,
        recordStatus
      );
      return saveStatusSuccess;
    }
  };

  const saveGuardianData = async (
    _enrolmentState,
    settingsState,
    isDeviceOnline,
    studyType
  ) => {
    if (studyType === "FGDA1") {
      const updateSuccess = await saveFGDA1GuardianData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "FGDA3") {
      const updateSuccess = await saveFGDA3GuardianData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "KIIA1") {
      const updateSuccess = await saveKIIA1GuardianData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "KIIA3") {
      const updateSuccess = await saveKIIA3GuardianData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "IDIA1") {
      const updateSuccess = await saveIDIA1GuardianData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "IDIA3") {
      const updateSuccess = await saveIDIA3GuardianData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    }
  };

  const saveParticipantData = async (
    _enrolmentState,
    settingsState,
    isDeviceOnline,
    studyType
  ) => {
    if (studyType === "FGDA1") {
      const updateSuccess = await saveFGDA1ParticipantData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "FGDA3") {
      const updateSuccess = await saveFGDA3ParticipantData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "IDIA1") {
      const updateSuccess = await saveIDIA1ParticipantData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "IDIA3") {
      const updateSuccess = await saveIDIA3ParticipantData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "KIIA1") {
      const updateSuccess = await saveKIIA1ParticipantData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    } else if (studyType === "KIIA3") {
      const updateSuccess = await saveKIIA3ParticipantData(
        _enrolmentState,
        settingsState,
        isDeviceOnline
      );
      return updateSuccess;
    }
  };

  const saveSubStudy = async (enrolmentState, settingsState, studyType) => {
    let _enrolmentState = JSON.parse(JSON.stringify(enrolmentState));
    setSyncInProgress(true);

    const _pageType =
      settingsState.currentFormIndex === 0
        ? settingsState.enrolmentForms[settingsState.currentFormIndex].type
        : settingsState.enrolmentForms[settingsState.currentFormIndex - 1].type;

    setLoading(true);

    const _participantId = await createNewParticipant(
      settingsState.loggedInUser.siteId
    );
    const _enrolmentId = await createSubStudy(_participantId, studyType);

    _enrolmentState.participant_id = _participantId;
    _enrolmentState.enrolment_id = _enrolmentId;

    const participantUpdateResults = await saveSubStudyParticipantUserData(
      _enrolmentState,
      settingsState,
      isDeviceOnline,
      studyType
    );

    const saveSubStudyStatusSuccess = await saveSubStudyStatus(
      _enrolmentState.participant_id,
      _enrolmentState.enrolment_id,
      settingsState.recordStatus,
      studyType
    );

    if (_pageType === "MINOR_PARENT" || _pageType === "MINOR_PARTICIPANT") {
      const updateGuardianSuccess = await saveGuardianData(
        _enrolmentState,
        settingsState,
        isDeviceOnline,
        studyType
      );
      const updateParticipantSuccess = await saveParticipantData(
        _enrolmentState,
        settingsState,
        isDeviceOnline,
        studyType
      );
      setLoading(false);

      const results =
        updateGuardianSuccess &&
        updateParticipantSuccess &&
        participantUpdateResults &&
        saveSubStudyStatusSuccess;
      _showSuccessMessage(results);

      return results;
    } else if (
      _pageType === "EMANCIPATED_MINOR" ||
      _pageType === "ADULT_18_PLUS" ||
      _pageType === "COMMUNITY_MEMBERS" ||
      _pageType === "PARENT_PARTICIPANT" ||
      _pageType === "KEY_INFORMANT"
    ) {
      const updateParticipantSuccess = await saveParticipantData(
        _enrolmentState,
        settingsState,
        isDeviceOnline,
        studyType
      );

      setLoading(false);

      const results =
        updateParticipantSuccess &&
        participantUpdateResults &&
        saveSubStudyStatusSuccess;
      _showSuccessMessage(results);
      return results;
    }

    // updateAuditInfoAndRedirect("FGDA1", status);
  };

  const syncData = async () => {
    const progressIncrementValue = 100 / offlineEnrolments.length;
    let totalProgress = progressIncrementValue;
    let failedRecords = [];

    for (const data of offlineEnrolments) {
      let result = null;

      if (data.study === "MAIN_STUDY") {
        result = await saveEnrolment(
          data.enrolment,
          data.settings,
          data.aouGuardianResults,
          data.aouParticipantResults
        );
      } else {
        result = await saveSubStudy(data.enrolment, data.settings, data.study);
      }

      if (result === false) {
        failedRecords.push(data);
      }

      setProgressValue(totalProgress);
      totalProgress = totalProgress + progressIncrementValue;
    }
    dispatch(setOfflineEnrolments(failedRecords));
    _showSuccessMessage(failedRecords.length === 0);
  };

  useEffect(() => {
    if (!isDeviceOnline) {
      history.push("/home");
    }
    dispatch(setAppTitle("MANAGE OFFLINE DATA"));
  }, []);

  useEffect(() => {
    let _rows = [];

    const ageCats = [
      "",
      "Age 15 To 17",
      "Age 15 - 17 Emancipated Minor",
      "Age 18+",
      "Parent / Legal Guardian as Participant",
      "Community Members",
      "Key Informants",
    ];
    offlineEnrolments.forEach((data, index) => {
      _rows.push({
        id: index + 1,
        age_cat: ageCats[data.settings.ageCat],
        guardian:
          data.enrolment.consent_guardian_first_name !== null
            ? `${data.enrolment.consent_guardian_first_name} ${data.enrolment.consent_guardian_first_name}`
            : "-",
        participant:
          data.enrolment.consent_participant_first_name !== null
            ? `${data.enrolment.consent_participant_first_name} ${data.enrolment.consent_participant_last_name}`
            : "-",
        enrolment_date: data.enrolment.created_date,
        record_status: data.settings.recordStatus,
      });
    });

    setRows(_rows);
  }, [offlineEnrolments]);

  return (
    <>
      <AppHeader isGuestMode={false} heading={"MANAGE OFFLINE DATA"} />
      <Container maxWidth="md">
        <div className={styles.pageBoxBody}>
          <Box sx={{ mt: 1 }}>
            <center>
              <Button
                variant="contained"
                disabled={syncInProgress}
                onClick={syncData}
              >
                Synchronise Offline Data ({offlineEnrolments.length})
              </Button>
            </center>
          </Box>
          <ProgressBar value={progressValue} />
        </div>
        <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div>
      </Container>

      <PreLoader show={Loading} />
    </>
  );
};

export default ManageOfflineData;
