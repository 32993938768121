import React, { useEffect, useState } from "react";
import styles from "./SelectAgeCategory.module.css";
import PreLoader from "../../../components/common/PreLoader";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAgeCat } from "../../../store/slices/settingsSlice";
import {
  setEnrolmentForms,
  setCurrentFormIndex,
} from "../../../store/slices/settingsSlice";

const SelectAgeCategory = ({ changeEnrolementStep }) => {
  const [t] = useTranslation("common");
  const history = useHistory();
  const [Loading] = useState(false);
  const dispatch = useDispatch();
  const { enrolmentForms, ageCatsList, studyName, isDeviceOnline } =
    useSelector((state) => state.settings);
  const selectedCountry = localStorage.getItem("i18nextLng").split("_")[1];

  const goBack = () => {
    history.push("/home");
  };

  const setCurrentEnrolmentForm = (index) => {
    let _enrolmentForms = JSON.parse(JSON.stringify(enrolmentForms));
    _enrolmentForms[index].isCurrent = true;
    dispatch(setEnrolmentForms(_enrolmentForms));
    dispatch(setCurrentFormIndex(index));
  };

  const SelectAgeCat = (ageCatId) => {
    dispatch(setAgeCat(ageCatId));

    if (studyName === "MAIN_STUDY") {
      if (ageCatId === 1) {
        setCurrentEnrolmentForm(0);
        changeEnrolementStep(3);
      } else if (ageCatId === 2) {
        setCurrentEnrolmentForm(8);
        changeEnrolementStep(4);
      } else if (ageCatId === 3) {
        setCurrentEnrolmentForm(12);
        changeEnrolementStep(4);
      }
    } else if (
      studyName === "FGDA1" ||
      studyName === "FGDA3" ||
      studyName === "IDIA1" ||
      studyName === "IDIA3"
    ) {
      if (ageCatId === 1) {
        setCurrentEnrolmentForm(0);
        changeEnrolementStep(5);
      } else if (ageCatId === 2) {
        setCurrentEnrolmentForm(2);
        changeEnrolementStep(5);
      } else if (ageCatId === 3) {
        setCurrentEnrolmentForm(3);
        changeEnrolementStep(5);
      } else if (ageCatId === 4) {
        setCurrentEnrolmentForm(4);
        changeEnrolementStep(4);
      } else if (ageCatId === 5) {
        setCurrentEnrolmentForm(5);
        changeEnrolementStep(4);
      }
    } else if (studyName === "KIIA1" || studyName === "KIIA3") {
      if (ageCatId === 6) {
        setCurrentEnrolmentForm(0);
        changeEnrolementStep(4);
      }
    } else if (studyName === "IC" || studyName === "IL") {
      if (ageCatId === 1) {
        setCurrentEnrolmentForm(0);
        changeEnrolementStep(3);
      } else if (ageCatId === 2) {
        setCurrentEnrolmentForm(2);
        changeEnrolementStep(4);
      } else if (ageCatId === 3) {
        setCurrentEnrolmentForm(3);
        changeEnrolementStep(4);
      }
    }
  };

  useEffect(() => {
    if (studyName === "KIIA1" || studyName === "KIIA3") {
      dispatch(setAgeCat(6));
      setCurrentEnrolmentForm(0);
      changeEnrolementStep(4);
    }
  }, []);

  return (
    <>
      <div className={styles.pageBody}>
        <div>
          <h2>{t("age_category_question")}</h2>
          {!isDeviceOnline && (
            <p style={{ color: "red" }}>
              Some age categories are not available in offline mode.
            </p>
          )}
        </div>
        {ageCatsList.map((ageCat, index) => (
          <div key={`age_cat_${index}`}>
            <Button
              disabled={
                (selectedCountry === "ZM" && ageCat.id === 2) ||
                (studyName !== "MAIN_STUDY" &&
                  isDeviceOnline === false &&
                  index <= 2)
              }
              onClick={() => {
                SelectAgeCat(ageCat.id);
              }}
              variant="contained"
              sx={{ mt: 3, mb: 2, minWidth: 300 }}
            >
              {t(ageCat.title)}
            </Button>
          </div>
        ))}
        <div>
          <Button
            variant="outlined"
            sx={{ mt: 3, mb: 2, minWidth: 300 }}
            onClick={goBack}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
      <PreLoader show={Loading} />
    </>
  );
};

SelectAgeCategory.propTypes = {
  changeEnrolementStep: PropTypes.func,
};

export default SelectAgeCategory;
